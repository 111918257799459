export const summaryData = [
  {
    img: "DashboardDeposite",
    key: "TODAYS_DEPOSIT",
    text: "Todays Deposit",
  },
  {
    img: "DashboardWithdrawal",
    key: "TODAYS_WITHDRAWAL",
    text: "Todays Withdrawal",
  },
  {
    img: "DashboardActiveBalance",
    key: "ACTIVE_BALANCE",
    text: "Active Balance",
  },
  {
    img: "DashboardUnsettled",
    key: "SETTLED_BALANCE",
    text: "Settled Balance",
  },
  {
    img: "DashboardUnsettled",
    key: "UNSETTLED_BALANCE",
    text: "UnSettled Balance",
  },
];

export const transactionSummaryData = [
  {
    img: "SucessIcon",
    key: "totalAmt",
    text: "Success",
  },
  {
    img: "PendingIcon",
    key: "totalAmt",
    text: "Pending",
  },
  {
    img: "IntializeIcon",
    key: "totalAmt",
    text: "Initialized",
  },
  {
    img: "ProcessingIcon",
    key: "totalAmt",
    text: "Processing",
  },
];

export const withdrawalSummaryData = [
  {
    img: "WithdrawSuccessIcon",
    key: "Success_sum",
    text: "Success",
    percentageKey: "success_percentage",
    amount: "success_payout_amount",
  },
  {
    img: "WithdrawPendingIcon",
    key: "pending_sum",
    text: "Pending",
    percentageKey: "pending_percentage",
    amount: "pending_payout_amount",
  },
  {
    img: "WithdrawIntializeIcon",
    key: "Initialized_sum",
    text: "Initialized",
    percentageKey: "initialized_percentage",
    amount: "initialized_payout_amount",
  },
  {
    img: "FailedIcon",
    key: "Failed_sum",
    text: "Failed",
    percentageKey: "failed_percentage",
    amount: "Failed_payout_amount",
  },
];

export const tableData: any[] = [
  {
    id: 1,
    bank_details: {
      account_holder_name: "John Doe",
      ifsc_code: "ABCD1234",
      upi_id: "john.doe@upi",
    },
    account_number: "1234567890",
    mmm: "kmk",
  },

  {
    id: 2,
    bank_details: {
      account_holder_name: "Jane Smith",
      ifsc_code: "EFGH5678",
      upi_id: "jane.smith@upi",
    },
    account_number: "9876543210",
  },
];
